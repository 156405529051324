<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Informações Gerais</span>

        <div class="row">
          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strNome"
            name="strNome"
            label="Nome"
            required
            rules="required"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strEmail"
            name="strEmail"
            label="Email"
            required
            rules="required|email"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strUsuario"
            name="strUsuario"
            label="Usuário"
            required
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strSenha"
            name="strSenha"
            label="Senha"
            type="password"
            :rules="(!formData.intId ? 'required' : '')"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
            v-model="formData.strEnderecoServidor"
            name="strEnderecoServidor"
            label="Endereço do Servidor"
            required
            rules="required"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            v-model="formData.intPorta"
            name="intPorta"
            label="Porta"
            type="number"
            required
            rules="required"
          />

          <ed-input-switch
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.boolAutenticacao"
            name="boolAutenticacao"
            label="Servidor requer autenticação?"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strAutenticacao"
            name="strAutenticacao"
            label="Tipo de Autenticação"
            :items="arrayTipoAutenticacao"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.intTamanhoPacote"
            name="intTamanhoPacote"
            label="Marketing - Nº Mensagens por Pacote"
            type="number"
            required
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.intTempoEsperaPacote"
            name="intTempoEsperaPacote"
            label="Marketing - Tempo espera entre Pacote (em segundos)"
            type="number"
            required
          />
          <ed-input-tipo
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.servicos"
            name="servicos"
            label="Serviços vinculados nessa conta"
            tipo="329"
            rules="required"
            required
            multiple
          />
          <ed-input-switch
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.boolAtivo"
            name="boolAtivo"
            label="Conta de email ativo?"
          />
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputSwitch,
  EdInputTipo,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
    EdInputSwitch,
    EdInputTipo,
    EdInputAutoComplete,
  },

  mounted() {},
  created() {},
  data() {
    return {
      arrayTipoAutenticacao: [
        { intId: "tls", strNome: "TLS" },
        //{ intId: "STARTTLS", strNome: "STARTTLS" },
        { intId: "ssl", strNome: "SSL" },
      ],
    };
  },
  watch: {},
  methods: {},
};
</script>
