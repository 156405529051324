<template>
  <div>
    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent"
    >
      <cadastro-geral :formData="formData" :key="'cadastro-' + $root.$session.versao" />

      <template slot="form-footer">
        <ed-button
          class="mr-2"
          color="warning"
          @click="testarConta"
          :disabled="!boolEnviarEmail"
          label="Testar Conta de Email"
          :iconLeft="$utilidade.getIcone('enviarEmail')"
        />
      </template>
    </ed-form>
  </div>
</template>

<script>
import { EdForm, EdButton } from "@/components/common/form";

import CadastroGeral from "./partials/geral.vue";

export default {
  name: "pageCadastroFuncao",
  props: {
    intContaEmailId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    EdButton,
  },
  mounted() {
    this.getContaEmail();
  },
  created() {},
  data() {
    return {
      formData: {
        intId: 0,
        strNome: null,
        strEmail: null,
        strUsuario: null,
        strSenha: null,
        strEnderecoServidor: null,
        boolAutenticacao: 1,
        boolAtivo: 1,
        intPorta: 587,
        strAutenticacao: "tls",
        intTamanhoPacote: 20,
        intTempoEsperaPacote: 5,
        servicos: [],
      },
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  computed: {
    boolEnviarEmail() {
      return this.formData.strNome &&
        this.formData.strEmail &&
        this.formData.strUsuario &&
        this.formData.strEnderecoServidor &&
        this.formData.intPorta
        ? true
        : false;
    },
  },
  methods: {
    getContaEmail() {
      if (!this.intContaEmailId) return;

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Configuracoes/ContaEmail", { intId: this.intContaEmailId })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            let arrayServicos = objResponse.result.servicos;

            objResponse.result.servicos = [];
            arrayServicos.forEach((element) => {
              objResponse.result.servicos.push(element.intTipoServicoId);
            });

            this.formData = Object.assign(objResponse.result, {});
          }
        });
    },

    testarConta() {
      this.$root.$dialog.loading(true);

      let formData = Object.assign(this.formData, {});
      formData.boolTestarContaEmail = true;

      this.$root.$request
        .get("Configuracoes/ContaEmail", formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.$root.$dialog.success(
              "Email enviado com sucesso para `" + this.formData.strEmail + "`"
            );
          }
        });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("Configuracoes/ContaEmail", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            if (this.$route.name != "configuracoes.contaEmail.edit") {
              this.$router.push({
                name: "configuracoes.contaEmail.edit",
                params: { intId: objResponse.result.intId },
              });
            } else {
              this.getContaEmail();
            }
          }
        });
    },
  },
};
</script>
